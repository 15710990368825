///
/// Contributors.jsx
///

import Table from "react-bootstrap/Table";

const contributors = [
  {
    name: "Enzo Alda",
    year: 2010,
    contributions: "Lilly Interpreter, REPL, Web Client Parser, Project Management",
  },
  {
    name: "Hernando M. Vidal",
    year: 2012,
    contributions: "MS-Excel Numerical & Statistical Functions",
  },
  {
    name: "Jaques Frenkel",
    year: 2015,
    contributions: "Web Client Lexical Analyzer",
  },
  {
    name: "Mónica Figuera",
    year: 2015,
    contributions: "ZenSheet Studio",
  },
  {
    name: "Wilmer Bandres",
    year: 2016,
    contributions: "MS-Excel Statistical Functions, Language Benchmarks",
  },
  {
    name: "Carlos Spaggiari",
    year: 2016,
    contributions: "MS-Excel Financial Functions",
  },
  {
    name: "Richard Lares Mejías",
    year: 2016,
    contributions: "ZenSheet Runtime Library Functions",
  },
  {
    name: "Juan Andrés Escalante",
    year: 2017,
    contributions: "Lilly Compiler",
  },
  {
    name: "Sam Bouiss",
    year: 2017,
    contributions: "Bitcoin Derivatives Model",
  },
  {
    name: "Amira Mouline",
    year: 2017,
    contributions: "Documentation Review, Testing",
  },
  {
    name: "Javier López Lombano",
    year: 2018,
    contributions: "Lilly Compiler, Cloud Deployment",
  },
  {
    name: "Pablo Maldonado",
    year: 2018,
    contributions: "Cloud Deployment",
  },
  {
    name: "Jacquin Mininger",
    year: 2019,
    contributions: "Web REPL, MongoDB Interface",
  },
  {
    name: "Piyush Chauhan",
    year: 2021,
    contributions: "REPL Additions",
  },
  {
    name: "Jean Paul Yazbek",
    year: 2021,
    contributions: "ZenSheet Studio X",
  },
  {
    name: "Daniel Pinto Alvarado",
    year: 2024,
    contributions: "Lilly VM, Web Assembly",
  },
];

const stupid_header_fixed = false;

const header = (
  <theader>
    <tr>
      <th>Name</th>
      <th>Year</th>
      <th>Contributions</th>
    </tr>
  </theader>
);

const row = item => (
  <tr key={item.name}>
    <td>{item.name}</td>
    { /* <td>{item.year}</td> */ }
    <td>{item.contributions}</td>
  </tr>
);

const body = <tbody>{contributors.map(row)}</tbody>;

const table = (
  <div className="Main-contributors">
    <h5>ZenSheet&trade; contributors in order of appearance</h5>
    <Table striped bordered hover variant="dark">
      {stupid_header_fixed ? header : null}
      {body}
    </Table>
  </div>
);

function Contributors() {
  return table;
}

export default Contributors;
